<template>
  <ui-component-modal
    modalTitle="Edit space"
    :onClickCancel="onClickCancel"
    :onClickSave="saveCartSpace"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :savingSuccessMessage="'Data saved'"
    :savingErrorMessage="'Something went wrong'"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :disableSaveButton="isValidating || showNotAvailable || blockSaveButton"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div v-if="cart && cart.MeetingTypeId < 3">
        <article v-if="showNotAvailable" class="message is-warning">
          <div class="message-body">
            <ul>
              <li v-if="showWarning(0)">
                <div>
                  {{
                    $t(
                      'Components.Carts.EditCartSpace.List_Location-is-closed-on-that-day'
                    )
                  }}
                </div>
                <div>
                  <a
                    @click="ignoreWarning(0)"
                    class="has-padding-left is-small is-link"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'ban']" />
                    </span>
                    <span>{{
                      $t('Components.Carts.EditCartSpace.Button_Ignore-warning')
                    }}</span></a
                  >
                </div>
              </li>
              <li v-if="showWarning(1)">
                <div>
                  {{
                    $t('Components.Carts.EditCartSpace.List_Location-is-closed')
                  }}
                </div>
                <div>
                  <a
                    @click="ignoreWarning(3)"
                    class="has-padding-left is-small is-link"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'ban']" />
                    </span>
                    <span>{{
                      $t('Components.Carts.EditCartSpace.Button_Ignore-warning')
                    }}</span></a
                  >
                </div>
              </li>
              <li v-if="showWarning(2)">
                <div>
                  {{
                    $t(
                      'Components.Carts.EditCartSpace.List_Space-is-not-available'
                    )
                  }}
                </div>
                <div>
                  <a @click="resetSpaceValue" class="is-small is-link">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'history']" />
                    </span>
                    <span>Undo</span>
                  </a>
                  <a
                    @click="setShowSpaceAlternatives(mSpace)"
                    class="has-padding-left is-small is-link"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'ban']" />
                    </span>
                    <span>{{
                      $t(
                        'Components.Carts.EditCartSpace.Button_Show-alternatives'
                      )
                    }}</span></a
                  >
                </div>
              </li>
              <li v-if="showWarning(3)">
                <div>
                  {{
                    $t(
                      'Components.Carts.EditCartSpace.List_Minimum-hours-are-not-met'
                    )
                  }}
                </div>
                <div>
                  <a @click="resetSpaceValue" class="is-small is-link">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'history']" />
                    </span>
                    <span>Undo</span>
                  </a>
                  <a
                    @click="ignoreWarning(3)"
                    class="has-padding-left is-small is-link"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'ban']" />
                    </span>
                    <span>{{
                      $t('Components.Carts.EditCartSpace.Button_Ignore-warning')
                    }}</span></a
                  >
                </div>
              </li>
              <li v-if="showWarning(4)">
                <div>
                  {{
                    $t(
                      'Components.Carts.EditCartSpace.List_Number-of-seats-doesnt-fit-in-selected-setting'
                    )
                  }}
                </div>
                <div>
                  <a @click="resetSpaceValue" class="is-small is-link">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'history']" />
                    </span>
                    <span>Undo</span>
                  </a>
                  <a
                    @click="ignoreWarning(4)"
                    class="has-padding-left is-small is-link"
                  >
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'ban']" />
                    </span>
                    <span>{{
                      $t('Components.Carts.EditCartSpace.Button_Ignore-warning')
                    }}</span></a
                  >
                </div>
              </li>
            </ul>
          </div>
        </article>

        <div>
          <table class="table is-striped is-fullwidth">
            <tbody>
              <tr>
                <td width="100">
                  {{ $t('Components.Carts.EditCartSpace.Table_Seats') }}
                </td>
                <td>&nbsp;</td>
                <td class="has-text-right">
                  <div class="field has-addons">
                    <p class="control">
                      <input
                        type="text"
                        @change="validateAvailability"
                        @keyup="delay(validateAvailability, 200)"
                        :disabled="showNotAvailable"
                        v-model.number="mSpace.Seats"
                        size="5"
                        class="input"
                        :class="{ 'is-danger': showNoSeatsWarning }"
                      />
                    </p>
                    <p class="control">
                      <a class="button is-static">X</a>
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('Components.Carts.EditCartSpace.Table_Setting') }}
                </td>
                <td>&nbsp;</td>
                <td class="has-text-right">
                  <div class="select">
                    <select
                      @change="validateAvailability"
                      v-model="mSpace.SettingId"
                      :disabled="showNotAvailable"
                    >
                      <option :value="0" :selected="space.SettingId === 0"
                        >Select a configuration</option
                      >
                      <option
                        v-for="setting in settings"
                        :key="setting.SettingId"
                        :value="setting.SettingId"
                        :selected="setting.SettingId === space.SettingId"
                        >{{ setting.SettingId | getSettingName }} ({{
                          setting.MinSeats
                        }}
                        - {{ setting.MaxSeats }})</option
                      >
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('Components.Carts.EditCartSpace.Table_Start') }}</td>
                <td>
                  <article v-if="openinghours" class="message is-info is-small">
                    <div class="message-body">
                      Location opens at:
                      {{ openinghours.MinMinutes | minutesToTime }}
                    </div>
                  </article>
                </td>
                <td class="has-text-right">
                  <div class="select">
                    <select
                      @change="validateAvailability"
                      v-model="mSpace.StartMinutes"
                      :disabled="showNotAvailable"
                    >
                      <option
                        v-for="timeSlot in timeSlots"
                        :key="timeSlot"
                        :value="timeSlot"
                        :selected="mSpace.StartMinutes === timeSlot"
                        >{{ timeSlot | minutesToTime }}</option
                      >
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('Components.Carts.EditCartSpace.Table_End') }}</td>
                <td>
                  <article v-if="openinghours" class="message is-info is-small">
                    <div class="message-body">
                      Location closes at:
                      {{ openinghours.MaxMinutes | minutesToTime }}
                    </div>
                  </article>
                </td>
                <td class="has-text-right">
                  <div class="select">
                    <select
                      @change="validateAvailability"
                      v-model="mSpace.EndMinutes"
                      :disabled="showNotAvailable"
                    >
                      <option
                        v-for="timeSlot in timeSlots"
                        :key="timeSlot"
                        :value="timeSlot"
                        :selected="mSpace.EndMinutes === timeSlot"
                        >{{ timeSlot | minutesToTime }}</option
                      >
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Tax</td>
                <td>&nbsp;</td>
                <td class="has-text-right">
                  <div class="select">
                    <select
                      v-model="mSpace.TaxPercentage"
                      :disabled="showNotAvailable"
                    >
                      <option
                        v-for="taxPercentage in taxPercentages"
                        :key="taxPercentage"
                        :value="taxPercentage"
                        :selected="taxPercentage === mSpace.TaxPercentage"
                        >{{ taxPercentage }}%</option
                      >
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('Components.Carts.EditCartSpace.Table_Price') }}</td>
                <td>&nbsp;</td>
                <td class="has-text-right">
                  <div class="field has-addons has-text-right">
                    <p class="control">
                      <a
                        class="button is-static"
                        v-html="mSpace.CurrencySymbol"
                      ></a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="mSpace.PricePerSeat"
                        :disabled="showNotAvailable"
                        class="input"
                        :class="{ 'is-danger': showNoPriceWarning }"
                        size="5"
                      />
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>{{ $t('Components.Carts.EditCartSpace.Table_Total') }}</td>
                <td>&nbsp;</td>
                <td class="has-text-right has-text-weight-bold">
                  <span v-html="mSpace.CurrencySymbol"></span>
                  <span>{{ meetingspaceTotalPrice.toFixed(2) }}</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import availabilityProvider from '@/providers/availability'
import cartProvider from '@/providers/cart'
import openinghoursProvider from '@/providers/openinghours'
import spaceProvider from '@/providers/space'
import taxProvider from '@/providers/tax'

export default {
  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },
    space: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      timer: null,
      check: null,
      checkString: '1#1#1#1',
      isAvailable: true,
      isValidating: false,
      isSavingError: false,
      isSavingSuccess: false,
      isSaving: false,
      mSpace: this.space,
      originalSpace: JSON.parse(JSON.stringify(this.space)),
      openinghours: null,
      settings: [],
      setupDone: false,
      showLocationOpenWarning: false,
      showLocationOpeningHoursWarning: false,
      showMinimumHoursWarning: false,
      showSeatsSettingWarning: false,
      showUnitAvailableWarning: false,
      timeSlots: [],
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapState('locationStore', ['locationState']),

    meetingspaceTotalPrice() {
      let total = 0.0

      if (this.mSpace) {
        if (this.mSpace.CalculationType === 'PerDayPartOnly') {
          total = Number(this.mSpace.PricePerSeat)
        } else {
          total =
            Math.round(
              Number(this.mSpace.Seats) * Number(this.mSpace.PricePerSeat) * 100
            ) / 100
        }
      }

      return total
    },

    taxPercentages() {
      let result = []

      if (this.locationState) {
        result = this.locationState.TaxPercentages.map((t) => t.TaxPercentage)
      }

      return result
    },

    showNotAvailable() {
      let show = false

      show = !this.isAvailable

      return show
    },

    blockSaveButton() {
      let self = this
      return self.showNoSeatsWarning || self.showNoPriceWarning
    },

    showNoSeatsWarning() {
      let self = this
      return String(self.mSpace.Seats).length === 0
    },

    showNoPriceWarning() {
      let self = this
      return String(self.mSpace.PricePerSeat).length === 0
    },
  },

  created() {
    this.getLocationOpeninghours()
    this.getSpaceData()
  },

  methods: {
    ...mapMutations('cartStore', ['setCart']),

    fillTimeSlots() {
      let min = this.isEmployee ? 0 : this.openinghours.MinMinutes
      let max = this.isEmployee ? 1440 : this.openinghours.MaxMinutes
      let minutes = 30

      let i = min
      while (i <= max) {
        this.timeSlots.push(i)
        i = i + minutes
      }
    },

    getSpaceData() {
      spaceProvider.methods
        .getSpaceById(this.space.SpaceId)
        .then((response) => {
          if (response.status === 200) {
            this.settings = response.data.Settings
          }
        })
    },

    getLocationOpeninghours() {
      openinghoursProvider.methods
        .getOpeningHours(
          this.space.StartDate,
          0,
          this.cart.LocationId,
          this.cart.MeetingtypeId
        )
        .then((response) => {
          if (response.status === 200) {
            this.openinghours = response.data
            this.fillTimeSlots()
          }
        })
        .then(() => {
          this.setupDone = true
        })
    },

    delay(callback, ms) {
      let self = this
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        callback.apply(self)
        clearTimeout(self.timer)
      }, ms || 0)
    },

    validateAvailability() {
      if (Number(this.mSpace.Seats) > 0) {
        this.isValidating = true
        this.mSpace.LocationId = this.cart.LocationId
        this.mSpace.ChannelId = this.cart.ChannelId
        this.mSpace.MeetingtypeId = this.cart.MeetingTypeId
        //this.mSpace.MeetingtypeId = this.meetingtypeId;

        availabilityProvider.methods
          .checkSpaceTimeAvailability(this.mSpace)
          .then((response) => {
            this.check = response.data
            let checksArray = this.check.CheckString.split('#')

            this.showLocationOpenWarning = Number(checksArray[0]) === 0
            this.showLocationOpeningHoursWarning = Number(checksArray[1]) === 0
            this.showUnitAvailableWarning = Number(checksArray[2]) === 0
            this.showMinimumHoursWarning = Number(checksArray[3]) === 0
            this.showSeatsSettingWarning = Number(checksArray[4]) === 0

            if (this.check.IsAvailable) {
              this.mSpace.PricePerSeat = this.check.PricePerSeat
              this.mSpace.PriceTotal = this.check.PriceTotal
              this.mSpace.Crc = this.check.Crc
            }

            this.isAvailable = this.check.IsAvailable
            this.checkString = this.check.CheckString
          })
          .finally(() => {
            this.isValidating = false
          })
      }
    },

    showWarning(index) {
      let checks = this.checkString.split('#')

      return Number(checks[index]) === 0
    },

    ignoreWarning(index) {
      let checks = this.checkString.split('#')
      checks[index] = 1

      let checkIndex = checks.findIndex((c) => c === 0)
      if (checkIndex === -1) {
        this.isAvailable = true
      }

      this.checkString = checks.join('#')
    },

    revertToOriginal() {
      let self = this
      self.mSpace = self.originalSpace
    },

    cancelEditCartSpace() {
      this.$emit('cancelEditSpace')
    },

    resetSpaceValue() {
      let self = this
      self.checkString = '1#1#1#1'
      self.isAvailable = true
      self.mSpace = JSON.parse(JSON.stringify(self.originalSpace))
    },

    saveCartSpace() {
      let self = this
      self.isSaving = true
      self.mSpace.PriceTotal = self.meetingspaceTotalPrice
      self.mSpace.StartDate = self.$options.filters.dateObjectIsoDateString(
        self.mSpace.StartDate
      )
      self.mSpace.EndDate = self.$options.filters.dateObjectIsoDateString(
        self.mSpace.EndDate
      )

      cartProvider.methods
        .saveCartSpace(self.cart.CartKey, self.mSpace)
        .then((response) => {
          if (response.status === 200) {
            self.setCart(response.data)
            self.isSavingSuccess = true

            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Space is saved!',
            })

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch((error) => {
          self.isSavingError = true

          EventBus.$emit('showToast', {
            type: 'error',
            message: 'Space not saved!',
          })
        })
        .finally(() => {
          self.isSaving = false
        })

      //self.$emit('dataSaved', self.mSpace)
    },
  },
}
</script>
